import React, { useEffect, useState } from 'react';

import { usePulseContext } from '../../mandatory-integrations/pulse/PulseProvider.js';

interface BackButtonProps {
  pulseId: string;
  children: React.ReactNode;
  url?: string;
  pulseName?: string;
  buttonClassName?: string;
  label?: string;
}

const BackButton: React.FC<BackButtonProps> = ({
  pulseId,
  children,
  url,
  pulseName = 'Go to previous page',
  buttonClassName = 'go-back',
  label = 'gå tilbake',
}) => {
  const [hasBrowserHistory, setHasBrowserHistory] = useState(true);
  const { trackClickUIElement } = usePulseContext();

  const handlePreviousPageClick = () => {
    trackClickUIElement({
      object: {
        id: pulseId,
        name: pulseName,
        elementType: 'Button',
      },
    }).finally(() => {
      if (url) {
        window.location.href = url;
      } else {
        history.back();
      }
    });
  };

  useEffect(() => {
    setHasBrowserHistory(history.length > 1);
  }, []);

  if (!hasBrowserHistory) return null;

  return (
    <button
      className={buttonClassName}
      onClick={handlePreviousPageClick}
      aria-label={label}
    >
      {children}
    </button>
  );
};

export default BackButton;
