import React from 'react';

import type { OptionsFromRequest } from '../../../../core/system/rendering/types.js';

import Error from './Error.js';
import { Saves } from './Saves/Saves.js';
import { Shared } from './Saves/Shared.js';
import VevSubscription from './VevSubscription.js';

const STATIC_COMPONENTS = (
  options: OptionsFromRequest,
): Record<string, React.ReactNode> => ({
  saves: (
    <Saves
      {...options.requestParams}
      isMobile={options.siteVersion === 'phone'}
    />
  ),
  shared: <Shared {...options.requestParams} />,
  error: <Error isMobile={options.siteVersion === 'phone'} />,
  vevSubscription: (
    <VevSubscription
      siteVersion={options.siteVersion}
      isHermesApp={options.isHermesApp}
    />
  ),
});

const prepareStaticComponent = (
  componentName: string,
  options: OptionsFromRequest,
): React.ReactNode => {
  return STATIC_COMPONENTS(options)[componentName];
};

export { prepareStaticComponent };
