import React from 'react';

import type { SaveListItem } from '../../../types/save-types.js';
import type { TeaserSize } from '../../../types/frapi-types.js';

import Image from './teaserComponents/Image.js';
import TeaserWrapper from './TeaserWrapper.js';

export interface TeaserSavedProps extends SaveListItem {
  index?: number;
  size: TeaserSize;
}

const TeaserSaved: React.FC<TeaserSavedProps> = (teaser) => {
  const urlParts = [
    teaser.id.split('/#')[0],
    teaser.slug ? `/${teaser.slug}` : '',
    teaser.listicleId ? `#${teaser.listicleId}` : '',
  ].join('');

  const link = `/artikkel/${urlParts}`;

  return (
    <TeaserWrapper
      teaser={teaser}
      classNames="teaser-saved"
      link={link}
      pageType="saved:teaser"
    >
      <div className="teaser-link-info">
        {teaser.imageId ? (
          <Image
            imageAsset={{ id: teaser.imageId }}
            width={120}
            height={120}
            title={teaser.title}
          />
        ) : (
          <div className="no-image-placeholder" />
        )}
        <div className="text-container">
          <div className="text">
            <div className="teaser-kicker">{teaser.kicker}</div>
            <div className="teaser-title">{teaser.title}</div>
            {teaser.tags && <div className="teaser-tags">{teaser.tags}</div>}
          </div>
          {teaser.grade && (
            <div className="front-grade">
              <span className="bold-grade">{teaser.grade}</span>
              <span>/6</span>
            </div>
          )}
        </div>
      </div>
    </TeaserWrapper>
  );
};
export default TeaserSaved;
