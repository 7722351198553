import React, { useState } from 'react';

import type { SaveList } from '../../../types/save-types.js';

import Image from './teaserComponents/Image.js';
import { RenameList } from '../saving/RenameList.js';
import { DeleteList } from '../saving/DeleteList.js';
import { ListMenu } from '../saving/ListMenu.js';
import { DEFAULT_LIST_NAME } from '../saving/utils.js';

interface ListTeaserProps {
  item: SaveList;
  isMobile: boolean;
}
export const ListTeaser: React.FC<ListTeaserProps> = ({ item, isMobile }) => {
  const [showDeleteOverlay, setShowDeleteOverlay] = useState(false);
  const [showEditList, setShowEditList] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);

  const name = item.name;
  const isDefaultList = name === DEFAULT_LIST_NAME;
  const thumbnail = item.content.at(-1);

  const onDeleteList = (e: React.MouseEvent) => {
    e.preventDefault();
    setShowDeleteOverlay(true);
  };

  const onEditList = (e: React.MouseEvent) => {
    e.preventDefault();
    setShowEditList(true);
  };

  return (
    <div className={`saves-list-container ${openMenu ? 'active' : ''}`}>
      <a
        href={`/mine-vink/${encodeURIComponent(name)}`}
        className="saves-link teaser-link"
      >
        {thumbnail?.imageId ? (
          <Image
            imageAsset={{ id: thumbnail.imageId }}
            height={360}
            width={360}
            title={thumbnail.title}
          />
        ) : (
          <div className="no-image-placeholder" />
        )}
        <div className="saves-list-bottom">
          <div className="saves-list-info">
            <div className="saves-list-name">{name}</div>
            <div className="saves-list-count">{`${item.content.length} funn`}</div>
          </div>

          {!isDefaultList && (
            <ListMenu
              list={item}
              openMenu={openMenu}
              setOpenMenu={setOpenMenu}
              handleDeleteList={onDeleteList}
              handleEditList={onEditList}
              isMobile={isMobile}
            />
          )}
        </div>
      </a>
      {showEditList && (
        <RenameList
          oldListName={name}
          handleOverlay={() => setShowEditList(false)}
        />
      )}
      {showDeleteOverlay && (
        <DeleteList
          name={name}
          handleOverlay={() => setShowDeleteOverlay(false)}
        />
      )}
    </div>
  );
};
