import React, { useRef } from 'react';

import type { SiteVersion } from '../../../../core/system/rendering/types.js';

import { Salesposter } from '../../components/articles/articleComponents/Salesposter/Salesposter.js';

interface VevSubscriptionProps {
  siteVersion: SiteVersion;
  isHermesApp: boolean;
}

const VevSubscription: React.FC<VevSubscriptionProps> = ({
  siteVersion,
  isHermesApp,
}) => {
  const pageRef = useRef(null);
  if (isHermesApp) {
    return (
      <div className="hermes-sales-page">
        Bruk en vanlig nettleser for å bli abonnent.
      </div>
    );
  }

  return (
    <div className="vev-subscription" ref={pageRef}>
      <Salesposter
        siteVersion={siteVersion}
        id="vink-header"
        referrer="vinklandingpage"
        articleRef={pageRef}
      />
    </div>
  );
};

export default VevSubscription;
