import React from 'react';

import { ListOverview } from './ListOverview.js';
import { ListContent } from './ListContent.js';
import { SkeletonOverview } from '../../../components/saving/SkeletonOverview.js';
import { SkeletonList } from '../../../components/saving/SkeletonList.js';
import {
  ListsProvider,
  useListsContext,
} from '../../../components/saving/ListsProvider.js';
import { LinkWithPulse } from '../../../../../core/mandatory-integrations/pulse/components/LinkWithPulse.js';

interface SavesProps {
  find?: string;
  isMobile: boolean;
}
export const Saves: React.FC<SavesProps> = ({ find, isMobile }) => {
  const SavedContent = () => {
    const { isLoading } = useListsContext();
    if (isLoading) {
      return find ? (
        <SkeletonList title={find} />
      ) : (
        <SkeletonOverview title={'Mine Vink'} />
      );
    }

    return find ? (
      <ListContent listName={find} isMobile={isMobile} />
    ) : (
      <ListOverview isMobile={isMobile} />
    );
  };

  return (
    <ListsProvider>
      <div className="saves-container">
        <div className="saves-content">
          <SavedContent />
        </div>
        <LinkWithPulse
          className="homescreen-button-centered"
          href="/"
          id="redirect-to-homescreen"
          name="Redirect to homescreen"
        >
          Gå til Vink-forsiden
        </LinkWithPulse>
      </div>
    </ListsProvider>
  );
};
