import React, { useEffect, useState } from 'react';

import type {
  ByfunnFrapiSearchResponse,
  FrapiTeaserItem,
} from '../../../types/frapi-types.js';

import Teaser from '../../components/teasers/Teaser.js';
import { ListsProvider } from '../../components/saving/ListsProvider.js';
import { frontendConfig } from '../../../../core/system/config/frontend.server.js';

const { cdnUrlInternal } = frontendConfig;

interface ErrorProps {
  isMobile: boolean;
}

const Error: React.FC<ErrorProps> = ({ isMobile }) => {
  const [teaserData, setTeaserData] =
    useState<ByfunnFrapiSearchResponse | null>(null);

  useEffect(() => {
    (async () => {
      try {
        const result = await fetch(`/api/sok?phrase=tips&size=4`);
        const res = await result.json();
        setTeaserData(res);
      } catch (_) {
        setTeaserData(null);
      }
    })();
  }, []);

  return (
    <ListsProvider>
      <div className={`not-found-wrapper ${isMobile ? 'phone' : 'desktop'}`}>
        <div className="top-part">
          <button
            className="back-button"
            onClick={() => {
              history.back();
            }}
          >
            <img
              className="back-button__arrow"
              src={`${cdnUrlInternal}/vink/images/icons/arrows/arrow_blue.svg`}
              alt="Back arrow"
            />
            Tilbake
          </button>
          <main className="main-content">
            <div className="main-content__illustration">
              <img
                className="illustration-image"
                src={`${cdnUrlInternal}/vink/images/illustrations/404.svg`}
                alt="Not found illustration"
              />
            </div>
            <div className="main-content__text">
              <p className="status-code">404</p>
              <h1 className="heading">
                Flaut! Det ser ut som siden har forduftet ...
              </h1>
              <p className="lead">
                Heldigvis har Vink mer å by på. Sjekk forsiden eller en av våre
                nyeste saker.
              </p>
              <a className="to-frontpage" href="https://vink.aftenposten.no/">
                Forsiden
              </a>
            </div>
          </main>
        </div>
        {teaserData && <LatestArticles articles={teaserData.articles} />}
      </div>
    </ListsProvider>
  );
};

const LatestArticles = ({ articles }: { articles: FrapiTeaserItem[] }) => {
  return (
    <div className="teasers bundle-header">
      <h2 className="teasers__heading">Fire ferske saker</h2>
      <div className="teasers__items-wrapper">
        {articles?.map((t) => (
          <Teaser
            {...t}
            key={t.id}
            size="small"
            lazyLoad={false}
            fullWidthTeaser={false}
            colorPalette={undefined}
          />
        ))}
      </div>
    </div>
  );
};

export default Error;
