import React from 'react';

export const SkeletonList: React.FC<{ title: string }> = ({ title }) => (
  <div className="skeleton-list">
    <div className="top">
      <div />
      <div />
    </div>
    <div className="skeleton-title-container">
      <div className="skeleton-count"></div>
      <div className="skeleton-list-title">{title}</div>
    </div>
    {[...Array(4)].map((_, i) => (
      <div className="line" key={i} />
    ))}
  </div>
);
