import React, { useEffect, useState } from 'react';

import type { SaveList } from '../../../../types/save-types.js';

import { SkeletonList } from '../../../components/saving/SkeletonList.js';
import TeaserSaved from '../../../components/teasers/TeaserSaved.js';
import { ListsProvider } from '../../../components/saving/ListsProvider.js';
import { SaveSharedList } from '../../../components/saving/SaveSharedList.js';
import BackButton from '../../../../../core/react/components/BackButton.js';
import { useDelayedVisibility } from '../../../hooks/useDelayedVisibility.js';
import Toast from '../../../components/commonComponents/Toast.js';

const errorMessage = 'Noe gikk galt.';
const successMessage = 'Listen er lagret!';

export const Shared: React.FC = () => {
  const [sharedList, setSharedList] = useState<SaveList | null>(null);
  const [showOverlay, setShowOverlay] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');
  const [showToast] = useDelayedVisibility(hasError);

  useEffect(() => {
    try {
      const { hash } = window.location;
      const object = window.atob(hash.substring(1));
      const decodedObject = JSON.parse(decodeURIComponent(object)) as SaveList;

      setSharedList(decodedObject as SaveList);
    } catch (_error) {
      setHasError(true);
      setStatusMessage(errorMessage);
    }
  }, []);

  const SaveButton = () => {
    const successFullySaved = statusMessage === successMessage;

    return (
      <button className="save-shared" onClick={() => setShowOverlay(true)}>
        {successFullySaved ? 'Lagret!' : 'Lagre en kopi av listen'}
      </button>
    );
  };

  const handleOverlay = (success?: boolean) => {
    setShowOverlay(false);
    if (success) {
      setStatusMessage(successMessage);
    }
  };

  return (
    <ListsProvider>
      <div className="saves-container">
        <div className="saves-content">
          {sharedList ? (
            <>
              <div className="saved-items-title-container">
                <div className="saved-items-top">
                  <BackButton pulseId="shareditems:previous-page">
                    <span className="icon"></span>
                    <span className="label">Tilbake</span>
                  </BackButton>
                  <SaveButton />
                </div>

                <div className="saved-items-count">{`${sharedList.content.length} funn`}</div>
                <h1 className="saved-items-title">{sharedList.name}</h1>
              </div>
              <div className="saved-items-teasers">
                {[...sharedList.content.slice().reverse()].map((c, i) => (
                  <div
                    className={`saved-item-container`}
                    key={`saved-item-${i}`}
                  >
                    <TeaserSaved index={i} {...c} size="small" />
                  </div>
                ))}
              </div>
            </>
          ) : (
            <SkeletonList title="Delt liste" />
          )}
        </div>
        {showOverlay && sharedList && (
          <SaveSharedList list={sharedList} handleOverlay={handleOverlay} />
        )}
      </div>
      {showToast && <Toast message={statusMessage} hasError />}
    </ListsProvider>
  );
};
