import React, { useState } from 'react';

import type { SaveList } from '../../../types/save-types.js';

import { usePulseContext } from '../../../../core/mandatory-integrations/pulse/PulseProvider.js';
import { useListsContext } from './ListsProvider.js';
import { LoginWall } from '../commonComponents/LoginWall.js';
import { Overlay } from '../commonComponents/Overlay.js';
import { listExists } from './utils.js';

interface SaveSharedListProps {
  list: SaveList;
  handleOverlay: (success?: boolean) => void;
}

export const SaveSharedList: React.FC<SaveSharedListProps> = ({
  list,
  handleOverlay,
}) => {
  const [listName, setListName] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { savedItems, saveItem } = useListsContext();
  const { trackClickUIElement } = usePulseContext();

  const hasListWithSameName = listExists(listName, savedItems);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setListName(e.target.value);
  };

  const sharedList = {
    ...list,
    name: listName || list.name,
    date: new Date().getTime(),
  };

  const handleSaveItem = () => {
    setIsSubmitting(true);
    saveItem(sharedList, () => handleOverlay(true));
  };

  const handleCreateList = () => {
    trackClickUIElement({
      object: {
        id: 'save:submit-new-list-button',
        name: 'Submit button for new lists',
        elementType: 'Button',
      },
    }).finally(() => handleSaveItem());
  };

  return (
    <LoginWall handleOverlay={handleOverlay}>
      <Overlay className="confirm-overlay shared" handleOverlay={handleOverlay}>
        <div className="confirm-overlay-title">Lagre en kopi av listen</div>
        {hasListWithSameName && !isSubmitting && (
          <div className="confirm-overlay-prompt">
            Det er allerede en liste med dette navnet.
          </div>
        )}
        <input
          type="text"
          name="name"
          onChange={handleOnChange}
          value={listName}
          placeholder="Gi listen et unikt navn"
        ></input>
        <div className="confirm-overlay-buttons">
          <button
            className="confirm-overlay-action-button"
            onClick={handleCreateList}
            disabled={hasListWithSameName || listName === ''}
          >
            Lagre listen
          </button>
          <button
            className="confirm-overlay-cancel-button"
            onClick={() => handleOverlay()}
          >
            Avbryt
          </button>
        </div>
      </Overlay>
    </LoginWall>
  );
};
