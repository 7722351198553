import { useState, useEffect } from 'react';

type UseDelayedVisibilityReturnType = [
  isVisible: boolean,
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>,
];

export const useDelayedVisibility = (
  trigger: string | boolean,
  duration = 1000,
  delay = 0,
): UseDelayedVisibilityReturnType => {
  const [isVisible, setIsVisible] = useState<boolean>(!!trigger);

  useEffect(() => {
    let showTimerId: number | null = null;
    let hideTimerId: number | null = null;

    if (trigger) {
      showTimerId = window.setTimeout(() => {
        setIsVisible(true);
      }, delay);

      hideTimerId = window.setTimeout(() => {
        setIsVisible(false);
      }, duration + delay);
    } else {
      setIsVisible(false);
    }

    return () => {
      if (showTimerId) clearTimeout(showTimerId);
      if (hideTimerId) clearTimeout(hideTimerId);
    };
  }, [trigger, duration, delay]);

  return [isVisible, setIsVisible];
};
