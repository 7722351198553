import React, { useState } from 'react';

import Toast from './Toast.js';
import { useDelayedVisibility } from '../../hooks/useDelayedVisibility.js';
import { usePulseContext } from '../../../../core/mandatory-integrations/pulse/PulseProvider.js';

interface ShareContentButtonProps {
  className: string;
  objectToShare: { title: string; text: string; url: string };
  buttonText: string;
  allowNative: boolean;
}
const CopyText: React.FC<ShareContentButtonProps> = ({
  className,
  objectToShare,
  buttonText,
  allowNative,
}) => {
  const [copiedFeedback, setCopiedFeedback] = useState('');
  const [hasError, setError] = useState(false);
  const [showToast] = useDelayedVisibility(copiedFeedback, 2500, 0);
  const { trackClickUIElement } = usePulseContext();

  const hasSuccess = copiedFeedback && !hasError;

  const handleShare = async (e: React.MouseEvent) => {
    e.preventDefault();
    if (
      allowNative &&
      navigator.canShare &&
      navigator.canShare(objectToShare)
    ) {
      navigator.share(objectToShare);
    } else {
      try {
        await navigator.clipboard.writeText(objectToShare.url);

        setCopiedFeedback('Lenke til listen er kopiert. Del med hvem du vil!');
        setError(false);
      } catch (_error) {
        setCopiedFeedback('Noe gikk galt');
        setError(true);
      }
    }
  };

  const handleClick = (e: React.MouseEvent) => {
    trackClickUIElement({
      object: {
        id: `save:click share button`,
        name: 'Click share button',
        elementType: 'Button',
      },
    }).finally(async () => await handleShare(e));
  };

  return (
    <button className={className} onClick={handleClick}>
      {hasSuccess ? 'Kopiert' : buttonText}
      {showToast && <Toast message={copiedFeedback} hasError={hasError} />}
    </button>
  );
};

export default CopyText;
