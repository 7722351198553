import React, { useState } from 'react';

import { usePulseContext } from '../../../../core/mandatory-integrations/pulse/PulseProvider.js';
import { useListsContext } from './ListsProvider.js';
import { Overlay } from '../commonComponents/Overlay.js';

export const RenameList: React.FC<{
  oldListName: string;
  handleOverlay: () => void;
}> = ({ oldListName, handleOverlay }) => {
  const { trackClickUIElement } = usePulseContext();
  const [newListName, setNewListName] = useState('');
  const { savedItems, editList } = useListsContext();

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewListName(e.target.value);
  };

  const handleEdit = () => {
    trackClickUIElement({
      object: {
        id: 'save:submit-edit-list-button',
        name: 'Submit button for editing lists',
        elementType: 'Button',
      },
    }).finally(() => {
      editList(oldListName, newListName);
      handleOverlay();
    });
  };

  return (
    <Overlay className="confirm-overlay" handleOverlay={handleOverlay}>
      <div className="confirm-overlay-title">Endre tittel på listen</div>
      <div className="confirm-overlay-prompt">{`Gi listen ${oldListName} et nytt navn. Opp til 50 tegn.`}</div>
      <input
        type="text"
        name="name"
        onChange={handleOnChange}
        value={newListName}
      ></input>
      <div className="confirm-overlay-buttons">
        <button
          className="confirm-overlay-action-button"
          onClick={handleEdit}
          disabled={
            !newListName || savedItems.some((l) => l.name === newListName)
          }
        >
          Lagre
        </button>
        <button
          className="confirm-overlay-cancel-button"
          onClick={handleOverlay}
        >
          Avbryt
        </button>
      </div>
    </Overlay>
  );
};
