import React, { useEffect, useRef, useState } from 'react';
import { createSalesposter } from '@smp-distribution/salesposter-utils';

import type { SalesposterResponse } from '@smp-distribution/salesposter-utils';
import type { ByfunnPromoArticle } from '../../../../../types/frapi-types.js';
import type { SiteVersion } from '../../../../../../core/system/rendering/types.js';

import { useEmbedHtml } from '../../../../hooks/useEmbedHtml.js';
import { mapSiteversionToDeviceType } from './utils.js';
import { usePulseContext } from '../../../../../../core/mandatory-integrations/pulse/PulseProvider.js';
import {
  trackSalesposterView,
  trackStaticPageView,
  withByfunnPulse,
} from '../../../../../pulse/index.js';

export type SalesposterProps = {
  siteVersion: SiteVersion;
  id: string;
  referrer?: string;
  article?: ByfunnPromoArticle;
  refDisplayName?: string;
  articleRef: React.RefObject<HTMLDivElement>;
};

const getRefId = (referrer: string): string => {
  switch (referrer) {
    case 'vinkapfront':
      return '/vinkapfront';
    case 'vinklandingpage':
      return '/vinklandingpage';
    default:
      return document.referrer.includes('facebook') ? 'vink_facebook' : '';
  }
};

const Salesposter: React.FC<SalesposterProps> = ({
  siteVersion,
  id,
  referrer,
  article,
  articleRef,
}) => {
  const [data, setData] = useState<SalesposterResponse | null>(null);

  const ref = useRef<HTMLIFrameElement>(null);

  const { registerPageLeaveTracking } = usePulseContext();

  useEffect(() => {
    if (articleRef.current) {
      registerPageLeaveTracking({
        objectElement: articleRef.current,
      });
    }
  }, [articleRef, registerPageLeaveTracking]);

  useEffect(() => {
    (async () => {
      try {
        const refId = referrer ? getRefId(referrer) : undefined;
        const reason = new URLSearchParams(window.location.search).get(
          'reason',
        );

        const res = await fetch(
          `/api/cm/vink/subscription${refId}?reason=${reason}`,
        );

        const json = await res.json();
        const salesposter = createSalesposter(json, {
          contentId: id,
          contentProvider: 'byfunn',
          contentType: article ? 'article' : 'other',
          deviceType: mapSiteversionToDeviceType(siteVersion),
          paywallType: 'paid',
          document,
          includeInsightReferer: true,
          window,
        });
        setData(salesposter);
      } catch (_error) {
        setData(null);
      }
    })();
  }, [id, siteVersion, referrer, article]);

  const { trackViewPage, trackViewSalesPoster } = usePulseContext();

  useEffect(() => {
    if (article && data?.pulseOrigin) {
      trackSalesposterView(trackViewSalesPoster, article, data.pulseOrigin);
    } else if (!article && !data?.pulseOrigin) {
      trackStaticPageView(trackViewPage, 'bli-abonnent');
    }
  }, [trackViewPage, article, data, trackViewSalesPoster]);

  const height = useEmbedHtml(ref, data?.salesposter);

  return (
    <div className="salesposter-container">
      {data?.salesposter ? (
        <iframe
          ref={ref}
          title={`salesposter-${id}`}
          height={Math.min(760, height)}
          width="100%"
          style={{ border: 'none', overflowY: 'hidden' }}
          seamless={true}
          scrolling="no"
        />
      ) : (
        <div>
          Vil du lese mer? <a href="/bli-abonnent">Bli abonnent</a>
        </div>
      )}
    </div>
  );
};

const SalesposterWithPulse = withByfunnPulse(Salesposter);

export { SalesposterWithPulse as Salesposter };
