import { hydrateReactRootComponent } from '../../core/js/hydrate-react.js';
import { observeImages } from '../../core/js/lazy-loading.js';
import { registerServiceWorker } from '../../core/js/register-service-worker.js';
import { StaticPage } from '../../../src/byfunn/views/layouts/staticPages/StaticPage.js';
import { initMblTracker } from '../../core/js/mbl-tracker.js';
import { getSessionData } from '../../core/js/schibsted-account/index.server.js';
import { initSourcepoint } from '../../core/js/sourcepoint.js';

window.addEventListener('DOMContentLoaded', async () => {
  await hydrateReactRootComponent(StaticPage);
  observeImages();
  getSessionData().then((sessionData) => {
    initMblTracker(sessionData);
  });
  initSourcepoint();
});

registerServiceWorker();
