import React from 'react';

import { usePulseContext } from '../../../../core/mandatory-integrations/pulse/PulseProvider.js';
import { useListsContext } from './ListsProvider.js';
import { Overlay } from '../commonComponents/Overlay.js';

export const DeleteList: React.FC<{
  name: string;
  handleOverlay: () => void;
}> = ({ name, handleOverlay }) => {
  const { trackClickUIElement } = usePulseContext();
  const { deleteList } = useListsContext();

  const handleDelete = (e: React.MouseEvent<HTMLElement>, name: string) => {
    e.preventDefault();
    trackClickUIElement({
      object: {
        id: 'save:delete-list-button',
        name: 'Delete list button',
        elementType: 'Button',
      },
    }).finally(() => deleteList(name));
  };

  return (
    <Overlay className="confirm-overlay" handleOverlay={handleOverlay}>
      <div className="confirm-overlay-title">{`Slett listen ${name}`}</div>
      <div className="confirm-overlay-prompt">
        Bekreft at du ønsker å slette denne listen.
      </div>
      <div className="confirm-overlay-buttons">
        <button
          className="confirm-overlay-action-button"
          onClick={(e) => {
            handleDelete(e, name);
            handleOverlay();
          }}
        >
          Slett listen
        </button>
        <button
          className="confirm-overlay-cancel-button"
          onClick={handleOverlay}
        >
          Avbryt
        </button>
      </div>
    </Overlay>
  );
};
