import React from 'react';

import type { SaveList } from '../../../types/save-types.js';

import { useOutsideClick } from '../../../../core/react/hooks/index.js';
import ShareContentButton from '../commonComponents/ShareContentButton.js';
import { canShare, listDataToShare } from './utils.js';

interface ListMenuProps {
  list: SaveList;
  openMenu: boolean;
  isMobile: boolean;
  setOpenMenu: (setOpen: boolean) => void;
  handleEditList: (e: React.MouseEvent) => void;
  handleDeleteList: (e: React.MouseEvent) => void;
}

export const ListMenu: React.FC<ListMenuProps> = ({
  list,
  openMenu,
  isMobile,
  setOpenMenu,
  handleEditList,
  handleDeleteList,
}) => {
  const handleClickOutside = () => {
    setOpenMenu(false);
  };

  const containerRef = useOutsideClick(handleClickOutside);

  return (
    <div className="menu-wrapper" ref={containerRef}>
      <button
        className="dropdown-button"
        onClick={(e) => {
          e.preventDefault();
          setOpenMenu(!openMenu);
        }}
      />
      {openMenu && (
        <div className="menu">
          <button className="edit-action" onClick={handleEditList}>
            Rediger tittel
          </button>
          {canShare(navigator) && !isMobile && (
            <ShareContentButton
              className="share-action"
              objectToShare={listDataToShare(window, list)}
              buttonText="Del listen"
              allowNative={true}
            />
          )}
          <button className="delete-action" onClick={handleDeleteList}>
            Slett listen
          </button>
        </div>
      )}
    </div>
  );
};
