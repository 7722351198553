import { useEffect, useState } from 'react';

const measureBodyHeight = (doc: Document): number => doc.body.offsetHeight;

/**
 * fix for bottom margin being cut
 * make body height not 100% of iframe (not all browsers)
 */
const embedCss = `
  body {
    margin: 0;
    padding-bottom: 1px;
    height: fit-content;
    height: max-content;
  }
`;

const writeIframeContent = (doc: Document, data?: { html: string }) => {
  const contents = [
    '<base target="_parent">',
    `<style>${embedCss}</style>`,
    `<body>`,
    data?.html || '',
    '</body>',
  ];
  doc.open();
  doc.write(contents.join(''));
  doc.close();
};

export const useEmbedHtml = (
  ref: React.RefObject<HTMLIFrameElement>,
  embed?: { html: string },
): number => {
  const [height, setHeight] = useState(0);

  useEffect(() => {
    let intervalId: NodeJS.Timeout | undefined;
    const doc = ref.current?.contentDocument;

    if (doc) {
      writeIframeContent(doc, embed);

      setHeight(measureBodyHeight(doc));
      intervalId = setInterval(
        () =>
          window.requestAnimationFrame(() => {
            if (intervalId) {
              setHeight(measureBodyHeight(doc));
            }
          }),
        500,
      );
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
        intervalId = undefined;
      }
    };
  }, [ref, embed]);

  return height;
};
