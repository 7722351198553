import React from 'react';

import { Overlay } from './Overlay.js';

interface ToastProps {
  message: string;
  hasError?: boolean;
}

const Toast: React.FC<ToastProps> = ({ message, hasError }) => {
  const classNames = ['toast', hasError ? 'error' : ''].join(' ');

  return (
    <Overlay className="toast">
      <div className={classNames}>{message}</div>
    </Overlay>
  );
};

export default Toast;
