import React from 'react';

export const SkeletonOverview: React.FC<{ title: string }> = ({ title }) => (
  <div className="skeleton-overview">
    <div className="skeleton-title">{title}</div>
    <div className="head" />
    {[...Array(5)].map((_, i) => (
      <div className="line" key={i} />
    ))}
  </div>
);
