import React, { useEffect } from 'react';

import type { OptionsFromRequest } from '../../../../core/system/rendering/types.js';
import type { StaticPageData } from '../../../types/binding-types.js';

import Navigation from '../../components/commonComponents/Navigation/Navigation.js';
import Footer from '../../components/commonComponents/Footer.js';
import { prepareStaticComponent } from '../staticPages/Components.js';
import { usePulseContext } from '../../../../core/mandatory-integrations/pulse/PulseProvider.js';
import {
  withByfunnPulse,
  trackStaticPageView,
  trackSectionPageView,
} from '../../../pulse/index.js';
import { DataController } from '../../components/commonComponents/DataController.js';

export type StaticPageProps = {
  data: StaticPageData;
  options: OptionsFromRequest;
};

const StaticPage: React.FC<StaticPageProps> = ({ data, options }) => {
  const { siteVersion, currentUrl } = options;
  const { componentName, pageType } = data;

  const component = prepareStaticComponent(componentName, options);

  const activeSection = currentUrl.split(/[?#]/)[0].substring(1);
  const sectionName = activeSection.split('/').pop();

  const { trackViewListing, trackViewPage } = usePulseContext();

  useEffect(() => {
    if (!data.customTrack) {
      if (pageType === 'Listing') {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        sectionName && trackSectionPageView(trackViewListing, sectionName);
      }

      if (pageType === 'Page') {
        trackStaticPageView(trackViewPage, componentName);
      }
    }
  }, [
    trackViewListing,
    currentUrl,
    componentName,
    pageType,
    data,
    sectionName,
    trackViewPage,
  ]);

  return (
    <div className={`${siteVersion}-front`}>
      <Navigation fullWidth={siteVersion !== 'phone'} />

      {component}

      <Footer isMobile={siteVersion === 'phone'} />
      <DataController />
    </div>
  );
};

const StaticPageWithPulse = withByfunnPulse(StaticPage);

export { StaticPageWithPulse as StaticPage };
