import React, { useState } from 'react';

import TeaserSaved from '../../../components/teasers/TeaserSaved.js';
import { usePulseContext } from '../../../../../core/mandatory-integrations/pulse/PulseProvider.js';
import { useListsContext } from '../../../components/saving/ListsProvider.js';
import BackButton from '../../../../../core/react/components/BackButton.js';
import Toast from '../../../../views/components/commonComponents/Toast.js';
import ShareContentButton from '../../../../views/components/commonComponents/ShareContentButton.js';
import { useDelayedVisibility } from '../../../hooks/useDelayedVisibility.js';
import { canShare, listDataToShare } from '../../../components/saving/utils.js';

interface ListContentProps {
  listName: string;
  isMobile: boolean;
}
export const ListContent: React.FC<ListContentProps> = ({
  listName,
  isMobile,
}) => {
  const [activeDelete, setActiveDelete] = useState(false);
  const [activeDeleteItems, setActiveDeleteItems] = useState<string[]>([]);
  const { savedItems, deleteItems, statusMessage, hasError } =
    useListsContext();
  const [showToast] = useDelayedVisibility(statusMessage);

  const { trackClickUIElement } = usePulseContext();
  const list = savedItems.find((l) => l.name === listName);
  if (!list) {
    return <></>;
  }

  const handleDeleteMultiple = async () => {
    trackClickUIElement({
      object: {
        id: 'save:delete-items-button',
        name: 'Delete items from list',
        elementType: 'Button',
      },
    });
    deleteItems(list, activeDeleteItems);
    setActiveDelete(false);
  };

  const handleSelectItem = (id: string) => {
    if (activeDeleteItems.includes(id)) {
      setActiveDeleteItems([...activeDeleteItems.filter((i) => i !== id)]);
    } else {
      setActiveDeleteItems([...activeDeleteItems, id]);
    }
  };

  return (
    <div>
      {showToast && hasError && <Toast message={statusMessage} hasError />}
      <div className="saved-items-top">
        <BackButton pulseId="saveditems:previous-page">
          <span className="icon"></span>
          <span className="label">Tilbake</span>
        </BackButton>
        {list.content.length > 0 && (
          <div className="list-content-actions">
            {canShare(navigator) && !isMobile && (
              <ShareContentButton
                className="share"
                objectToShare={listDataToShare(window, list)}
                buttonText="Del listen"
                allowNative={true}
              />
            )}
            <button
              className="delete-icon"
              onClick={() => setActiveDelete(true)}
            >
              Slett saker
            </button>
          </div>
        )}
      </div>
      <div className="saved-items-title-container">
        <div className="saved-items-count">{`${list.content.length} funn`}</div>
        <h1 className="saved-items-title">{list.name}</h1>
      </div>
      <div className="saved-items-teasers">
        {[...list.content].reverse().map((c, i) => (
          <div
            className={`saved-item-container ${
              activeDeleteItems.includes(c.id) ? 'active' : ''
            }`}
            key={`saved-item-${i}`}
          >
            {activeDelete && (
              <button
                className={`saved-item-delete-button`}
                onClick={() => handleSelectItem(c.id)}
              />
            )}
            <TeaserSaved index={i} {...c} size="small" />
          </div>
        ))}
      </div>
      {activeDelete && (
        <div className="delete-buttons-container">
          <button
            className="delete-action-button"
            onClick={handleDeleteMultiple}
          >
            Slett
          </button>
          <button
            className="delete-cancel-button"
            onClick={() => {
              setActiveDelete(false);
              setActiveDeleteItems([]);
            }}
          >
            Avbryt
          </button>
        </div>
      )}
    </div>
  );
};
