import React, { useState } from 'react';

import { NewList } from '../../../components/saving/NewList.js';
import { ListTeaser } from '../../../../views/components/teasers/ListTeaser.js';
import { usePulseContext } from '../../../../../core/mandatory-integrations/pulse/PulseProvider.js';
import { useListsContext } from '../../../components/saving/ListsProvider.js';
import { sortSavedItems } from '../../../components/saving/utils.js';
import Toast from '../../../../views/components/commonComponents/Toast.js';
import { useDelayedVisibility } from '../../../hooks/useDelayedVisibility.js';

export const ListOverview: React.FC<{ isMobile: boolean }> = ({ isMobile }) => {
  const [newList, toggleNewList] = useState(false);
  const { trackClickUIElement } = usePulseContext();
  const { savedItems, statusMessage, hasError } = useListsContext();
  const [showToast] = useDelayedVisibility(statusMessage);

  const handleToggleNewList = () => {
    toggleNewList(!newList);
  };

  const handleOpenModal = () => {
    trackClickUIElement({
      object: {
        id: 'save:new-list-button',
        name: 'New list button on save page',
        elementType: 'Button',
      },
    }).finally(() => handleToggleNewList());
  };

  return (
    <div className="list-overview">
      {showToast && hasError && <Toast message={statusMessage} hasError />}
      <h1 className="saves-title">Mine Vink</h1>
      <button className="create-new-list" onClick={handleOpenModal}>
        Lag ny liste
      </button>
      {newList && (
        <NewList
          handleOverlay={handleToggleNewList}
          handleCancel={handleToggleNewList}
        />
      )}
      <div className="saves-list">
        {savedItems.length > 0 &&
          sortSavedItems(savedItems).map((i) => (
            <ListTeaser item={i} key={i.name} isMobile={isMobile} />
          ))}
      </div>
    </div>
  );
};
